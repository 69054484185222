<template>

  <div>
    <b-card>
      <AgGrid
        ref="MIDGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Query By MID'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="OpenCreateByMID()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">By MID #</span></b-button>
        </template>

      </AgGrid>

      <b-modal
        id="CreateMidModal"
        ref="CreateByMid"
        title="New MID Query"
        size="lg"
        centered
        no-close-on-backdrop
      >
        <validation-observer
          ref="MIDRules"
          tag="form"
        >
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="MID #"
                  rules="required|max:15"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>MID #:</b></i></label>
                    <b-form-input
                      id="MID"
                      v-model="MID"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="MID Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </validation-observer>
        <template #modal-footer>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1"
              @click="createbymid()"
            >
              <span class="align-middle">Save</span></b-button>
          </div>
        </template>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIMIDViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      aggrid: [],
      MID: '',
    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('MID')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'mid',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'MID #', field: 'mid', minWidth: 145,
      },
      {
        headerName: 'Firm Name', field: 'firmName', minWidth: 350,
      },
      {
        headerName: 'Country', field: 'country', minWidth: 100,
      },
      {
        headerName: 'Address', field: 'address', minWidth: 450,
      },
      {
        headerName: 'City', field: 'city', minWidth: 165,
      },
      {
        headerName: 'Zip/Postal', field: 'zip', minWidth: 100,
      },
      {
        headerName: 'Last Updated', field: 'lastUpdated', minWidth: 175,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.MIDGrid.saveState('MID') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.MIDGrid.saveState('MID') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.MIDGrid.saveState('MID') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.MIDGrid.saveState('MID') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(mid) {
      this.MID = mid
      this.$router.push(`/abi/query/manufacturer/detail/${mid}`)
    },
    DoubleClickOpenPopup(params) {
      this.MID = params.data.mid
      this.$router.push(`/abi/query/manufacturer/detail/${params.data.mid}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadMID()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadMID() {
      axios.get('/abi/query/mid/all')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.MIDGrid.ResetColumns()
        })
    },
    OpenCreateByMID() {
      this.$refs.CreateByMid.show()
    },
    createbymid() {
      this.$refs.MIDRules.validate().then(result => {
        if (result) {
          this.LoadingStart()

          const MIDQueryModel = {
            MID: this.MID,
          }

          axios.post('/abi/query/mid', MIDQueryModel)
            .then(() => {
              this.LoadingEnd()
              this.$refs.CreateByMid.hide()
              this.DisplaySuccess('Query data has been submitted.').then(() => {
                this.LoadMID()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateByMid.hide()
              this.DisplayError('Query cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Query cannot be saved', 'Please check data requirements.')
        }
      })
    },
    Delete(mid) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/abi/query/mid/${mid}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('MID has been removed.')
              .then(() => {
                // redirect
                this.LoadMID()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove MID.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}
</script>
